<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-width="$root.rwd < 3 ? '130px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<el-form-item label="帳號" prop="account">
			<el-input v-model="ruleForm.account" placeholder="８碼以上英文字母與半形數字" class="round" />
		</el-form-item>
		<el-form-item label="密碼">
			<router-link to="/change-password">
				<el-button type="info" size="small">修改密碼</el-button>
			</router-link>
		</el-form-item>
		<el-form-item label="信箱" prop="email">
			<el-input v-model="ruleForm.email" placeholder="電子信箱" class="round" />
		</el-form-item>
		<el-form-item label="姓名" prop="name">
			<div class="row-grid" gutter-x="30" gutter-y="20" align-y="center">
				<div>
					<el-input v-model="ruleForm.name" placeholder="姓名" class="round" />
				</div>
				<el-form-item prop="gender" style="margin:0!important;">
					<div class="row-grid" align-y="center" gutter-y="10">
						<template v-for="(option, i) in gender_options">
							<el-radio v-model="ruleForm.gender" :label="option" :key="i" />
						</template>
					</div>
				</el-form-item>
			</div>
		</el-form-item>
		<template v-if="visible('member')">
			<el-form-item label="出生年" prop="birthday">
				<el-date-picker v-model="ruleForm.birthday" value-format="timestamp" type="year" placeholder="選擇年份" class="round" />
			</el-form-item>
			<el-form-item label="手機號碼" prop="cellphone">
				<el-input v-model="ruleForm.cellphone" class="round" />
			</el-form-item>
			<el-form-item label="聯絡電話" prop="phone">
				<el-input v-model="ruleForm.phone" class="round" />
			</el-form-item>
			<el-form-item label="通訊地址" required>
				<div class="row-grid" gutter-x="10">
					<el-form-item prop="zipcode">
						<el-input v-model="ruleForm.zipcode" placeholder="郵遞區號" style="width:auto;" class="round" />
					</el-form-item>
					<el-form-item prop="city">
						<el-select v-model="ruleForm.city" placeholder="縣市" class="round" @change="$set(ruleForm, 'district', '')">
							<template v-for="(val, key, i) in administrativeDistrict">
								<el-option :value="key" :key="i" />
							</template>
						</el-select>
					</el-form-item>
					<el-form-item prop="district">
						<el-select v-model="ruleForm.district" placeholder="鄉鎮市區" class="round">
							<template v-for="(district, i) in (administrativeDistrict[ruleForm.city] || new Array)">
								<el-option :value="district" :key="i" />
							</template>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item prop="address">
					<el-input v-model="ruleForm.address" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
				</el-form-item>
			</el-form-item>
		</template>
		<template v-if="visible('donor')">
			<h2 class="theme_text">椅友會員</h2>
			<hr>
			<el-form-item label-width="0">
				<b class="theme_text">年度捐款1000元(含)以上。您可選擇⼀次贊助１年或一次贊助２年以上。</b>
			</el-form-item>
			<el-form-item label="抬頭" prop="title">
				<el-input v-model="ruleForm.title" class="round" />
			</el-form-item>
			<el-form-item label="身分證／統編" prop="identity">
				<el-input v-model="ruleForm.identity" class="round" />
			</el-form-item>
			<el-form-item label-width="0">
				<b class="theme_text">捐款收據可作為年度申報所得稅列舉扣除之憑證{{"\u3000"}}敬請費心填寫。</b>
			</el-form-item>
			<el-form-item label="年度捐款金額" prop="amount_per_year">
				<b class="theme_text">NT${{"\u3000"}}</b>
				<el-input-number v-model.number="ruleForm.amount_per_year" :min="1000" />
			</el-form-item>
			<el-form-item label="共捐助" prop="donation_years">
				<el-input-number v-model.number="ruleForm.donation_years" :min="1" />
				<b class="theme_text">{{"\u3000"}}年</b>
			</el-form-item>
			<el-form-item label="捐款⾦額總計">
				<b class="theme_text">NT${{"\u3000"}}{{(ruleForm.amount_per_year && ruleForm.donation_years && ruleForm.amount_per_year * ruleForm.donation_years || 0) | numberFilter}}</b>
			</el-form-item>
			<el-form-item label="郵寄地址">
				<div class="row-grid" gutter-x="10">
					<el-form-item prop="recipient_zipcode">
						<el-input v-model="ruleForm.recipient_zipcode" placeholder="郵遞區號" style="width:auto;" class="round" />
					</el-form-item>
					<el-form-item prop="recipient_city">
						<el-select v-model="ruleForm.recipient_city" placeholder="縣市" class="round" @change="ruleForm.recipient_district = ''">
							<template v-for="(val, key, i) in administrativeDistrict">
								<el-option :value="key" :key="i" />
							</template>
						</el-select>
					</el-form-item>
					<el-form-item prop="recipient_district">
						<el-select v-model="ruleForm.recipient_district" placeholder="鄉鎮市區" class="round">
							<template v-for="(district, i) in (administrativeDistrict[ruleForm.recipient_city] || new Array)">
								<el-option :value="district" :key="i" />
							</template>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item prop="recipient_address">
					<el-input v-model="ruleForm.recipient_address" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
				</el-form-item>
			</el-form-item>
			<el-form-item label-width="0">
				<b class="theme_text">金枝將在相關出版品中刊登名銜感謝您的捐款，如不方便，請勾選下行。</b>
				<br>
				<el-checkbox v-model="ruleForm.anonymous">我樂於捐款，但盼不公開姓名，請以「隱名氏」呈現。</el-checkbox>
			</el-form-item>
		</template>
		<template v-if="visible('volunteer')">
			<h2 class="theme_text">志工團</h2>
			<hr>
			<br>
			<el-form-item label="綽號" prop="nickname">
				<el-input v-model="ruleForm.nickname" class="round" />
			</el-form-item>
			<el-form-item label="飲食習慣" prop="diet">
				<el-radio v-model="ruleForm.diet" label="葷食" />
				<el-radio v-model="ruleForm.diet" label="素食(蛋、奶可)" />
				<el-radio v-model="ruleForm.diet" label="全素" />
			</el-form-item>
			<el-form-item label="最高學歷" prop="education">
				<div class="row-grid" align-y="center" gutter-y="10">
					<template v-for="(option, i) in education_options">
						<el-radio v-model="ruleForm.education" :label="option" :key="i" />
					</template>
					<div class="row" align-y="center">
						<el-radio v-model="ruleForm.education" :label="education_options.includes(ruleForm.education) ? '' : ruleForm.education">其他</el-radio>
						<el-input v-model="ruleForm.education" :disabled="education_options.includes(ruleForm.education)" placeholder="其他" class="round" />
					</div>
				</div>
			</el-form-item>
			<el-form-item label="服務單位／就讀學校" prop="unit">
				<el-input v-model="ruleForm.unit" class="round" />
			</el-form-item>
			<el-form-item label="社團、工作或志工服務經驗" prop="volunteer_experience">
				<el-input v-model="ruleForm.volunteer_experience" type="textarea" :autosize="{minRows:5}" />
			</el-form-item>
			<el-form-item label="個人專長" prop="personal_expertise">
				<el-input v-model="ruleForm.personal_expertise" type="textarea" :autosize="{minRows:5}" />
			</el-form-item>
			<el-form-item label="願意參與志工類型（可複選）" prop="volunteer_type">
				<el-checkbox-group v-model="ruleForm.volunteer_type">
					<template v-for="(option, i) in type_options">
						<el-checkbox :label="option" :key="i" />
					</template>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="可服務區域（可複選）" prop="volunteer_region">
				<el-checkbox-group v-model="ruleForm.volunteer_region">
					<template v-for="(option, i) in region_options">
						<el-checkbox :label="option" :key="i" />
					</template>
				</el-checkbox-group>
			</el-form-item>
		</template>
		<template v-if="visible('member')">
			<el-divider />
			<el-form-item label="訂閱">
				<el-checkbox v-model="ruleForm.subscribe" label="我同意收到金枝電子報" />
			</el-form-item>
		</template>
	</el-form>
</template>

<script>
import { mapState } from "vuex";
import administrativeDistrict from "@/assets/data/administrative-district.json";
import { rule } from "@/utils/shared-methods";
const ruleForm = function () {
	return {
		volunteer_type: [],
		volunteer_region: [],
	}
}
export default {
	data() {
		return {
			administrativeDistrict,
			ruleForm: new ruleForm,
			rules: {
				account: rule(/^[a-zA-Z0-9]{8,}$/, "帳號（８碼以上英文字母與半形數字）"),
				email: [
					{ required: true, message: "必填" },
					{ type: "email", message: "請填正確信箱" }
				],
				name: rule(),
				gender: rule(),
				birthday: rule(),
				cellphone: rule(/^[0-9]{10}$/, "請填寫正確手機（10位數字）"),
				city: rule(),
				district: rule(),
				zipcode: rule(),
				address: rule(),
				amount_per_year: [
					{ required: true, message: "年度捐款金額", trigger: "blur" },
					{ type: "number", message: "請填數字", trigger: "blur" },
				],
				donation_years: [
					{ required: true, message: "共捐助Ｎ年", trigger: "blur" },
					{ type: "number", message: "請填數字", trigger: "blur" },
				],
				diet: rule(),
				volunteer_experience: rule(),
				personal_expertise: rule(),
				volunteer_type: rule(),
				volunteer_region: rule(),
			},
			gender_options: ["先生", "女士", "其他"],
			education_options: ["碩士(含)以上", "大專院校", "高中職", "國中"],
			type_options: [
				"行政：行政事務、資料蒐集及檔案建立等。大台北地區志工每週擇一日，固定時段至辦公室協助行政事務；外縣市志工可報名後電話聯繫，視活動內容需要協助在地工作。",
				"宣傳：支援美術製作、文宣、網路訊息編撰及露出等。",
				"新聞：展演現場實況文字記錄",
				"采風：展演現場實況攝影記錄",
				"活動：展演現場工作人員與前台服務人員",
			],
			region_options: [
				"全台走透透沒問題",
				"北部（台北、新北、基隆、宜蘭、桃園、新竹地區）",
				"中部（苗栗、台中、南投、彰化、雲林地區）",
				"南部（含嘉義、台南、高雄、屏東地區）",
				"東部（花蓮、台東地區）",
				"離島",
			],
		}
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	methods: {
		visible(role) {
			return this._auth && this._auth.roles.includes(role);
		},
		async validate() {
			return new Promise((resolve, reject) => {
				this.$refs["ruleForm"].validate((valid, object) => {
					if (valid) resolve(this.ruleForm);
					else reject("請確認表格皆填寫完畢");
				});
			});
		},
		resetFields() {
			this.ruleForm = new ruleForm;
			this.$refs["ruleForm"].resetFields();
		}
	},
	created() {
		this.ruleForm = this._.cloneDeep(this._auth);
	}
}
</script>