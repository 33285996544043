export function rule(regex, errMsg) {
	return [
		{ required: true, message: "必填" },
		{
			validator: (rule, value, callback) => {
				if (!regex || (regex && regex.test(value))) {
					callback();
				} else {
					callback(new Error(errMsg || "格式錯誤"));
				}
			},
			trigger: "blur"
		}
	];
}